<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title slot="start"><strong>Welcome</strong></ion-title>
        <ion-icon slot="end" size="large" src="..\assets\icon\settings.svg"></ion-icon>
      </ion-toolbar>
    </ion-header>
    
    <ion-content >
      <div id="page-container">
        <button type="button" class="page-button">
          <ion-icon src="../assets/icon/garage.svg"></ion-icon>
          <span>Garage</span>
        </button>
        <button class="page-button">
          <ion-icon src="../assets/icon/chat.svg"></ion-icon>
          <span>Chat</span>
        </button>
        <button class="page-button">
          <ion-icon src="../assets/icon/car.svg"></ion-icon>
          <span>Setup</span>
        </button>
        <button class="page-button">
          <ion-icon src="..\assets\icon\report.svg"></ion-icon>
          <span>Report</span>
        </button>
      </div>
      <div class="session-parent-container">
        <ion-title><strong>Your Sessions</strong></ion-title>
        <div class="session-child-container">
          <ion-segment scrollable>
            <div class="session-button-container">
              <button type="button" class="session-button">
                Lime Rock
                <ion-icon src="../assets/icon/racetrack1.svg"></ion-icon>
              </button>
              <span>Dates: 7/12/22</span>
            </div>
            <div class="session-button-container">
              <button type="button" class="session-button">
                Lime Rock
                <ion-icon src="../assets/icon/racetrack1.svg"></ion-icon>
              </button>
              <span>Dates: 7/12/22</span>
            </div>
            <div class="session-button-container">
              <button type="button" class="session-button">
                Lime Rock
                <ion-icon src="../assets/icon/racetrack1.svg"></ion-icon>
              </button>
              <span>Dates: 7/12/22</span>
            </div>
            <div class="session-button-container">
              <button type="button" class="session-button">
                Lime Rock
                <ion-icon src="../assets/icon/racetrack1.svg"></ion-icon>
              </button>
              <span>Dates: 7/12/22</span>
            </div>
          </ion-segment>
        </div>
      </div>
      <div class="new-session-container">
        <button type="button" id="open-modal" class="new-session-button">
          New Session
        </button>
      </div>
      <session-popup></session-popup>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonIcon,
  IonSegment,
  } from '@ionic/vue';

import SessionPopup from '../components/SessionPopup.vue';

export default({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonSegment,
    SessionPopup,
  },
});
</script>

<style scoped>

ion-toolbar {
  --background: #333333;
  --padding-end: 20px;
  --padding-start: 12px;
  --padding-top: 20px;
}

ion-content{
  --background: #333333;
}

ion-page {
  --background-color: #333333;
}

.page-button ion-icon {
  color: black;
  font-size: 45px;
  padding-right: 18px;
}

#page-container{
  position: flex;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: #333333;
  
  padding-top: 5%;
}

.page-button {
  background-color: #4D4D4D; /* dark grey */
  height: 80px;
  width: 155px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  
  
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000000;
}

.page-button:hover {
  color: black;
  background-color: #4CAF50;
}

.session-parent-container{
  position: flex;
  left: 0;
  right: 0;

  background-color: #333333;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  padding-top: 2%;
}

.session-child-container{
  position: flex;
  left: 0;
  right: 0;
  width: 100%;

  background-color: #333333;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-bottom: 5%;
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  
}

.session-button-container{
  position: flex;
  left: 0;
  right: 0;

  background-color: #333333;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin-bottom: 7%
}
.session-button-container span{
  font-size: 12px;
  color: #E6E6E6;
  padding-left: 10px;
}

.session-button{
  background-color: #4D4D4D; /* dark grey */


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  text-decoration: none;
  font-size: 16px;
  
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000000;
}

.session-button ion-icon {
  color: black;
  font-size: 100px;
}

.new-session-container{
  position: flex;
  left: 0;
  right: 0;

  background-color: #333333;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  /* margin-left: 10%;
  margin-right: 10%; */
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.new-session-button{
  background-color: #4D4D4D; /* dark grey */

  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  
  
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000000;
}

.container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container a {
  text-decoration: none;
}
</style>
