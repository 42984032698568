<template>
      <ion-modal ref="modal" trigger="open-next">
        <ion-content>
          <ion-toolbar>
            <div class="session-popup-header">
              <span class="session-popup-title"><strong>New Session</strong></span>
              <span class="session-popup-note"><strong>Enviornment and Condition</strong></span>
            </div>
            <ion-buttons slot="end">
              <ion-button color="light" @click="dismiss()">X</ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div class="session-popup-content">
            <div class="enviornment-dropdowns">
              <ion-accordion-group>
                <ion-accordion>
                  <ion-item slot="header" color="secondary">
                    <ion-label>Temperature</ion-label>
                  </ion-item>
                  <div class="ion-padding" slot="content">
                    28
                  </div>
                  <div class="ion-padding" slot="content">
                    29
                  </div>
                  <div class="ion-padding" slot="content">
                    30
                  </div>
                  <div class="ion-padding" slot="content">
                    31
                  </div>
                  <div class="ion-padding" slot="content">
                    32
                  </div>
                </ion-accordion>
                <ion-accordion>
                  <ion-item slot="header" color="secondary">
                    <ion-label>Weather</ion-label>
                  </ion-item>
                  <div class="ion-padding" slot="content">
                    Fiar
                  </div>
                  <div class="ion-padding" slot="content">
                    Rain
                  </div>
                  <div class="ion-padding" slot="content">
                    Full Sun
                  </div>
                  <div class="ion-padding" slot="content">
                    Over Cast
                  </div>
                </ion-accordion>
                <ion-accordion>
                  <ion-item slot="header" color="secondary">
                    <ion-label>Time of Day</ion-label>
                  </ion-item>
                  <div class="ion-padding" slot="content">
                    12:00 pm
                  </div>
                </ion-accordion>
                <ion-item>
                  <ion-label position="floating" color="light">General Track Conditions</ion-label>
                  <ion-input></ion-input>
                </ion-item>
              </ion-accordion-group>
            </div>
            <div class="session-popup-buttons">
              <button type="button" id="open-" class="session-cont-button"
              @click="$router.push('/session')">Create Session</button>
            </div>
          </div>
        </ion-content>
    </ion-modal>
</template>

<script>
import { 
  IonContent, 
  IonToolbar, 
  IonModal,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  } from '@ionic/vue';

export default({
  name: 'next-session',
  components: {
    IonContent,
    IonToolbar,
    IonModal,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
  },
  methods: {
    dismiss() {
      this.$refs.modal.$el.dismiss();
    },
  },
})
</script>

<style scoped>
.session-popup-header{
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.session-popup-title{
  color: #CCCCCC;
  font-size: 20px;
}

.session-popup-note{
  color: #999999;
  font-size: 12px;
}

.upload-text{
  font-size:12px;
  color:#CCCCCC;
}

.session-popup-buttons{
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.session-cont-button{
  position: flex;
  left: 0;
  right: 0;

  background-color: #cccccc;
  font-size: 20px;
  border: 2px solid #666666; 
  border-radius: 8px;
  color: #000000;

  padding-block: 2%;
  padding-inline: 6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin-block: 5%;
}

.session-best-time-button{
  position: flex;
  left: 0;
  right: 0;

  background-color: #cccccc;
  font-size: 20px;
  border: 2px solid #666666; 
  border-radius: 8px;
  color: #000000;

  padding-block: 2%;
  padding-inline: 6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin-top: 2%;
}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal ion-contnet{
  --background: #AD2E2A;
}

.session-popup-content{
  background-color: #AD2E2A;
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border: 2px solid #7b7b7b;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  padding-block: 1.5%;
  padding-inline: 1.5%;
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: #AD2E2A;
  --color: white;
}
</style>
