<template>
    <ion-modal ref="modal" trigger="open-modal">
        <ion-content>
          <ion-toolbar>
            <div class="session-popup-header">
              <span class="session-popup-title"><strong>New Session</strong></span>
              <span class="session-popup-note"><strong>General Information</strong></span>
            </div>
            <ion-buttons slot="end">
              <ion-button color="light" @click="dismiss()">X</ion-button>
            </ion-buttons>
          </ion-toolbar>
          <div class="session-popup-content">
            <ion-item>
              <ion-label position="floating" color="light">Session Name</ion-label>
              <ion-input></ion-input>
            </ion-item>
            <ion-accordion-group>
              <ion-accordion value="first">
                <ion-item slot="header" color="secondary">
                  <ion-label><span>Select Track</span></ion-label>
                </ion-item>
                <div class="ion-padding" slot="content">
                  <button class="dropdown-content">Lime Rock</button>
                </div>
              </ion-accordion>
              <ion-accordion value="second">
                <ion-item slot="header" color="secondary">
                  <ion-label><span>Select Setup</span></ion-label>
                </ion-item>
                <div class="ion-padding" slot="content">
                  <button class="dropdown-content">Setup 2a</button>
                </div>
              </ion-accordion>
            </ion-accordion-group>
            <div class="session-popup-buttons">
              <span class="upload-text">Upload your best track time : Disabled</span>
              <button type="button" class="session-best-time-button">Photo upload</button>
              <button type="button" id="open-next" class="session-cont-button"
              @click="dismiss()">Next</button>
              <SessionPopupEC />
            </div>
          </div>
        </ion-content>
    </ion-modal>
</template>

<script>
import { 
  IonContent, 
  IonToolbar, 
  IonModal,
  IonAccordion,
  IonItem,
  IonLabel,
  } from '@ionic/vue';

import SessionPopupEC from '../components/SessionPopupEC.vue';

export default ({
  name: 'session-popup',
  components: {
    IonContent,
    IonToolbar,
    IonModal,
    IonAccordion,
    IonItem,
    IonLabel,
    SessionPopupEC,
  },
  methods: {
    dismiss() {
      this.$refs.modal.$el.dismiss();
    },
  },
})
</script>

<style scoped>

.dropdown-content{

}
.session-popup-header{
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.session-popup-title{
  color: #CCCCCC;
  font-size: 20px;
}

.session-popup-note{
  color: #999999;
  font-size: 12px;
}

.upload-text{
  font-size:12px;
  color:#CCCCCC;
}

.session-popup-buttons{
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.session-cont-button{
  position: flex;
  left: 0;
  right: 0;

  background-color: #cccccc;
  font-size: 20px;
  border: 2px solid #666666; 
  border-radius: 8px;
  color: #000000;

  padding-block: 2%;
  padding-inline: 6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin-top: 5%;
}

.session-best-time-button{
  position: flex;
  left: 0;
  right: 0;

  background-color: #7c7c7c;
  font-size: 20px;
  border: 2px solid #666666; 
  border-radius: 8px;
  color: #000000;

  padding-block: 2%;
  padding-inline: 6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  margin-top: 2%;
}

.upload-text{
  padding-top: 9px;
  font-size: 14px;
}



ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal ion-contnet{
  --background: #AD2E2A;
}

.session-popup-content{
  background-color: #AD2E2A;
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border: 2px solid #7b7b7b;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  padding-block: 1.5%;
  padding-inline: 1.5%;
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: #AD2E2A;
  --color: white;
}
</style>
