<template>
    <ion-page>
        <ion-content>
            <button class="tap-anywhere" @click="$router.push('/login')">
                <div class="header">
                    <span class="title"><strong>Trackside</strong></span>
                    <span class="subtitle">by</span>
                    <ion-img src="../assets/images/057Logo.png"></ion-img>
                </div>
                <div class="content">
                    
                </div>
            </button>
        </ion-content>
    </ion-page>
</template>

<script>
import { 
    IonPage,
    IonContent,
    IonImg
 } from '@ionic/vue'

export default ({
    name: 'LoadingPage',
    componenets: {
        IonPage,
        IonContent,
        IonImg
    },
})
</script>

<style scoped>
.header{
    background-color: #ad2e2a;
    position: block;
    width: 100%;
    height: 85%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    font-size: 60px;
    font-style: italic;
}
.subtitle{
    font-size: 35px;
}
ion-img{
    width:100%;
}
.content{
    background-color: #ad2e2a;
    position: block;
    width: 100%;
    height: 15%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tap-anywhere{
    width:100%;
    height:100%;
    padding-inline: 0px;
    padding-block: 0px;
}
.tap-anywhere:hover{
    background-color: #000000;
}

</style>
