<template>
    <ion-page>
        <ion-header color="primary">
            <ion-toolbar>
                <ion-title>Your Session</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content color="primary">
            <div class="page-container">
                <div class="session-track">
                    <div class="track-title">
                        <span><strong>Track: Lime Rock</strong></span>
                    </div>
                    <ion-img src="../assets/images/LimeRock.png"></ion-img>
                </div>
                <div class="session-corners">
                    <ion-accordion-group expand="inset">
                        <ion-accordion>
        <ion-item slot="header" color="medium">
            <span><strong>Corner 1</strong></span>
        </ion-item>
        <div class="corner-content" slot="content">
            <div class="content-img-feedback">
                <ion-thumbnail>
                    <ion-img src="../assets/images/corners/Corner1.png"></ion-img>
                </ion-thumbnail>
                <div class="feedback">
                        <ion-list>
                        <div class="feedback-responses">
                            <span class="corner-response">Under</span>
                            <span class="corner-response">Neutral</span>
                            <span class="corner-response">Over</span>
                        </div>
                        
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Entry</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Apex</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Exit</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        </ion-list>
                </div>
            </div>
            <div class="comments">
                <ion-item>
                    <ion-label position="floating">Comments</ion-label>
                    <ion-textarea auto-grow="true"></ion-textarea>
                </ion-item>
            </div>
        </div>
    </ion-accordion>
    <ion-accordion>
        <ion-item slot="header" color="medium">
            <span><strong>Corner 2</strong></span>
        </ion-item>
        <div class="corner-content" slot="content">
            <div class="content-img-feedback">
                <ion-thumbnail>
                    <ion-img src="../assets/images/corners/Corner2.png"></ion-img>
                </ion-thumbnail>
                <div class="feedback">
                        <ion-list>
                        <div class="feedback-responses">
                            <span class="corner-response">Under</span>
                            <span class="corner-response">Neutral</span>
                            <span class="corner-response">Over</span>
                        </div>
                        
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Entry</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Apex</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Exit</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        </ion-list>
                </div>
            </div>
            <div class="comments">
                <ion-item>
                    <ion-label position="floating">Comments</ion-label>
                    <ion-textarea auto-grow="true"></ion-textarea>
                </ion-item>
            </div>
        </div>
    </ion-accordion>
    <ion-accordion>
        <ion-item slot="header" color="medium">
            <span><strong>Corner 3</strong></span>
        </ion-item>
        <div class="corner-content" slot="content">
            <div class="content-img-feedback">
                <ion-thumbnail>
                    <ion-img src="../assets/images/corners/Corner3.png"></ion-img>
                </ion-thumbnail>
                <div class="feedback">
                        <ion-list>
                        <div class="feedback-responses">
                            <span class="corner-response">Under</span>
                            <span class="corner-response">Neutral</span>
                            <span class="corner-response">Over</span>
                        </div>
                        
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Entry</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Apex</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Exit</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        </ion-list>
                </div>
            </div>
            <div class="comments">
                <ion-item>
                    <ion-label position="floating">Comments</ion-label>
                    <ion-textarea auto-grow="true"></ion-textarea>
                </ion-item>
            </div>
        </div>
    </ion-accordion>
    <ion-accordion>
        <ion-item slot="header" color="medium">
            <span><strong>corner 4</strong></span>
        </ion-item>
        <div class="corner-content" slot="content">
            <div class="content-img-feedback">
                <ion-thumbnail>
                    <ion-img :src="image"></ion-img>
                </ion-thumbnail>
                <div class="feedback">
                        <ion-list>
                        <div class="feedback-responses">
                            <span class="corner-response">Under</span>
                            <span class="corner-response">Neutral</span>
                            <span class="corner-response">Over</span>
                        </div>
                        
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Entry</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Apex</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        <ion-radio-group>
                            <ion-item>
                                <span class="corner-breakdown">Exit</span>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                                <ion-radio slot="end" color="secondary"></ion-radio>
                            </ion-item>
                        </ion-radio-group>
                        </ion-list>
                </div>
            </div>
            <div class="comments">
                <ion-item>
                    <ion-label position="floating">Comments</ion-label>
                    <ion-textarea auto-grow="true"></ion-textarea>
                </ion-item>
            </div>
        </div>
    </ion-accordion>
                    </ion-accordion-group>
                    <div class="submitpage">
                        <button class="submitpage-button">Submit</button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonAccordion,
    IonItem,
    IonLabel,
    IonTextarea,
    IonRadio,
  IonAccordionGroup,
  IonToolbar, 
  IonTitle,
  IonThumbnail,
    IonImg,
  
  } from '@ionic/vue';

export default({
  name: 'SessionPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonAccordionGroup,
    IonToolbar,
    IonTitle,
    IonAccordion,
    IonItem,
    IonLabel,
    IonTextarea,
    IonRadio,
    IonThumbnail,
    IonImg,

  },
  data(){
    return{
      corners:[
        { id: "corner1",
          image: "../assets/images/corners/Corner1.png", 
          name: "Corner 1", 
        },
        { id: "corner2",
          image: "../assets/images/corners/Corner2.png", 
          name: "Corner 2", 
        },
        { id: "corner3",
          image: "../assets/images/corners/Corner3.png", 
          name: "Corner 3", 
        },
        { id: "corner4",
          image: "../assets/images/corners/Corner4.png", 
          name: "Corner 4", 
        },
        { id: "corner5",
          image: "../assets/images/corners/Corner5.png", 
          name: "Corner 5", 
        },
        { id: "corner6",
          image: "../assets/images/corners/Corner6.png", 
          name: "Corner 6", 
        },
        { id: "corner7",
          image: "../assets/images/corners/Corner7.png", 
          name: "Corner 7", 
        },
        { id: "corner8",
          image: "../assets/images/corners/Corner8.png", 
          name: "Corner 8", 
        },
        { id: "corner9",
          image: "../assets/images/corners/Corner9.png", 
          name: "Corner 9", 
        },
        { id: "corner10A",
          image: "../assets/images/corners/Corner10A.png", 
          name: "Corner 10A", 
        },
        { id: "corner10B",
          image: "../assets/images/corners/Corner10B.png", 
          name: "Corner 10B", 
        },
        { id: "corner11",
          image: "../assets/images/corners/Corner11.png", 
          name: "Corner 11", 
        },
        { id: "corner12",
          image: "../assets/images/corners/Corner12.png", 
          name: "Corner 12", 
        },
             
      ],
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #333333;
  --padding-end: 20px;
  --padding-start: 12px;
  --padding-top: 20px;
}

.page-container{
  position: flex;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: #333333;
  
  padding-top: 5%;
}
.session-track{
  background-color: #AD2E2A;
  position: block;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border-radius: 10px;
  border: 1px solid #808080;

  padding-block: 1.5%;
  padding-inline: 1.5%;
}

.track-title{
  background-color: #808080;
  position: block;
  left: 0;
  right: 0;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  border-radius: 10px;
  border: 1px solid #000000;

  padding-block: 1.5%;
  padding-inline: 1.5%;
}

.session-corners{
  background-color: #333333;
  position: block;
  left: 0;
  right: 0;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  /* align-items: center; */
  border-radius: 16px;
  border: 0px solid #808080;

  /* padding-block: 1.5%;
  padding-inline: 1.5%; */
}

.corner-content{
  position: block;
  left: 0;
  right: 0;
  width:100%;

  background-color: #333333;

  display: flex;
  flex-wrap: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  padding-top: 2%;
}

.content-img-feedback{
  position: flex;
  left: 0;
  right: 0;
  width:100%;

  background-color: #333333;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  
  padding-top: 2%;
}

.content-img-feedback ion-thumbnail{
    --size: 120px;
}
.content-img-feedback ion-img::part(image) {
  border-radius: 10px;
  border: 2px solid #000000;
  margin-right: 8px;
}
.feedback ion-item{
    --background: #333333;
}

.feedback ion-radio::part(container) {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #808080;
}

.feedback{
    display: block;
    justify-content: space-between;
    margin-left: 8px;
    border: 2px solid #000000;
    border-radius: 5px;
}

ion-radio + ion-radio{
    margin-left: 28px;
}

.corner-response{
    font-size: 14px;
    align-content: center;
}
.feedback-responses{
    background-color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30%;
    padding-right: 5%;
    
}

.comments{
    width: 100%;
    padding-block: 2px;
    padding-inline: 2px;
}

.comments ion-textarea{
    --border-radius: 10px;
}

.submitpage-button{
  background-color: #ad2e2a; /* dark grey */

  display: flex;
  width: 45%;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  
  
  margin-top: 80px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
}
</style>