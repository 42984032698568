<template>
    <ion-page>
        <ion-content>
            <div class="header">
                <span class="title"><strong>Your</strong></span>
                <span class="title"><strong>Trackside</strong></span>
                <button class="login"  @click="$router.push('/home')">Login</button>
                <button class="signup">SIGN UP</button>
                
            </div>
            <div class="content">
               <span class="subtitle">by 057 Motorsports</span> 
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { 
    IonPage,
    IonContent,
    IonImg
 } from '@ionic/vue'

export default ({
    name: 'LoginPage',
    componenets: {
        IonPage,
        IonContent,
        IonImg
    },
})
</script>

<style scoped>
.header{
    background-color: #333333;
    position: block;
    width: 100%;
    height: 85%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    font-size: 35px;
}
.subtitle{
    font-size: 25px;
}
.login{
  background-color: #ad2e2a; /* dark grey */

  display: flex;
  width: 45%;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  
  
  margin-top: 80px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
}
.signup{
    background-color: #4d4d4d; /* dark grey */

  display: flex;
  width: 45%;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  
  
  margin-top: 35px;
  margin-bottom: 130px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
}
.content{
    background-color: #333333;
    position: block;
    width: 100%;
    height: 15%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #969696;
}

</style>