import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import SessionPage from '../views/SessionPage.vue'
import LoadingPage from '../views/LoadingPage.vue'
import LoginPage from '../views/LoginPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/loading'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/session',
    name: 'Session',
    component: SessionPage
  },
  {
    path: '/loading',
    name: 'LoadingPage',
    component: LoadingPage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
